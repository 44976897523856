<template>
    <div class="background-image">
        <div class="check-registration-container">
            <h3>Herzlich Willkommen beim Vilna Portal</h3>
            <div v-if="showMessage" class="message-container">
                <p v-if="isKeyValid" class="success-message">{{message}}</p>
                <p v-else class="error-message">{{message}}</p>
            </div>
            <div v-else class="loading-container">
                <img class="vilna-logo" src="../assets/vilna_loading_spinner.gif" alt="">
            </div>
            <button class="btn" @click="checkRegToLogin">Zum Login</button>
        </div>
    </div>
</template>

<script>
import Util from '../mixins/util'

export default{
    data () {
    return {
            name: 'Check Registration',
            message: '',
            showMessage: false,
            isKeyValid: false
        }
    },
    mounted: async function () {
        console.log('key', this.$route.params.key)
        let response = await window.dataController.CheckRegistrationKey(this.$route.params.key)
        if (response && response.status && response.status === 'ok') {
            this.isKeyValid = true
            this.showMessage = true
            this.message = 'Die Aktivierung war erfolgreich. Über den Button gelangen Sie zum Login.'
        } else {
            this.showMessage = true
            this.message = 'Die Aktivierung war nicht erfolgreich!'
        }
    },
    methods: {
        checkRegToLogin: function () {
            this.navigate('Login')
        }
    },
    mixins: [Util]
}

</script>

<style scoped>

.background-image {
    background-image: url('/chart.jpg') ;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.check-registration-container {
    background-color:rgba(255,255,255,0.8);
    border-radius:25px;
    padding: 25px;
    width: 50%;
    align-self: center;
    box-shadow: 25px 15px 10px rgb(0 0 0 / 40%);
    text-align: center;
}
.btn {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    margin: 4rem 0 1rem 0;
    font-size: 1.2rem;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #092A67, #476DB4);
    cursor: pointer;
    color: #ffffffff;
    text-transform: uppercase;
    background-size: 200%;
    transition: .5s;
}
.btn:hover {
    background-position: right;
}
.error-message {
    color: red;
}
.success-message {
    color: green;
}
@media (max-width: 576px) {
    .check-registration-container {
        width: 90%;
    }
}
</style>
